.App.is-mobile {
  .Authentication {
    .datepicker {
      height: 38px;
      text-align: center;

      &[value=""] {
        &::before {
          color: #000;
          content: attr(placeholder);
        }

        &:focus::before {
          content: "" !important;
        }
      }
    }
  }
}
