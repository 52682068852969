@font-face {
  font-family: 'OpenSans-Regular';
  src: url("./fonts/OpenSans-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url("./fonts/OpenSans-Bold.ttf") format('truetype');
}

body {
  padding: 0;
  height: 100%;
}

.App {
  padding: 0 !important;
  font-size: 16px;
  font-family: 'OpenSans-Regular', sans-serif;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  color: #575756;
  font-weight: 500;
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-items: end;
  overflow: hidden;

  &.is-mobile {
    max-width: unset;
  }

  > img.Header {
    max-width: 100%;
  }
}

.Header {
  background-color: #ececea;
  display: grid;
  padding: 27px 0;
  justify-items: center;
  border-bottom: 30px #7bc4ed solid;
}

.epas-logo {
  max-width: 200px;
  width: 100%;
}

.divider {
  margin-top: 100px;
  line-height: 0;
}

.divider img {
  width: 100%;
}

.Footer {
  overflow-x: hidden;
}

.footer-nav {
  color: #d7edf9;
  background-color: #6199b7;
  text-align: center;
  padding: 18px 0;
  vertical-align: baseline;
  justify-items: center;
  justify-content: center;
  list-style-type: none;
}

.footer-spacing {
  margin: auto;
  padding: 0;
  list-style: none;
}

.footer-spacing li {
  display: inline-block;
}

.footer-nav a {
  font-family: 'OpenSans-Bold', sans-serif;
  text-decoration: none;
  color: #d7edf9;
  font-size: 14px;
  padding: 0 11px;
  transition: all 0.4s ease-in-out;
}

.footer-nav a:hover {
  opacity: .7;
}

@media (min-width: 768px) and (max-width: 980px) {
  .App {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .App {
    font-size: 12px;
  }
}

form .row {
  margin: 0;
}

.alert {
  margin: 12px;
}

p {
  padding: 16px;
}